import React, { Component } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from '@mui/material';
import { Link } from 'react-router-dom';

class Welcome extends Component {
  state = {
    data: [],
    activeSpokes: 0,
    inactiveSpokes: 0,
    isDataLoaded: false,
  };

  componentDidMount() {
    // Use Promise.all to handle multiple requests
    Promise.all([
      axios.get(
        'https://www.dev-linkbackend.cloudetel.com:3443/fetchTotalBranches'
      ),
      axios.get('https://www.dev-linkbackend.cloudetel.com:3443/fetchdata'),
      axios.get(
        'https://www.dev-linkbackend.cloudetel.com:3443/activebranches'
      ),
      axios.get(
        'https://www.dev-linkbackend.cloudetel.com:3443/inactivebranches'
      ),
    ])
      .then(
        ([
          totalBranchesResponse,
          fetchDataResponse,
          activeBranchesResponse,
          InactiveBranchesResponse,
        ]) => {
          // Set the state for each response
          this.setState({
            data: fetchDataResponse.data,
            isDataLoaded: true,
            activeSpokes: activeBranchesResponse.data.length,
            inactiveSpokes: InactiveBranchesResponse.data.length,
          });
        }
      )
      .catch((error) => {
        console.error(error);
      });
  }

  fetchInactiveBranches() {
    axios
      .get('https://www.dev-linkbackend.cloudetel.com:3443/inactivebranches')
      .then((response) => {
        this.setState({ inactiveSpokes: response.data.length });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleLogout = () => {
    // Call your deleteCookie function here to delete the user authentication cookie
    this.deleteCookie('isvalid');
    this.deleteCookie('user');
    this.deleteCookie('refresh_token');
    this.deleteCookie('access_token_expiry');
    this.deleteCookie('access_token');
    alert('Logged Out Successfully');
    setTimeout(() => {
      window.location.reload(); // Refresh the page after 1 second
    }, 1000);
  };

  renderSubnets(subnets) {
    if (Array.isArray(subnets)) {
      return subnets.map((subnet, index) => (
        <span key={index}>
          {subnet}
          <br />
        </span>
      ));
    } else {
      return <span>{subnets}</span>;
    }
  }
  deleteCookie(cookieName) {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  render() {
    const flexBoxStyle = {
      flex: '1',
      margin: '10px',
      padding: '15px',
      borderRadius: '8px',
    };

    const totalSpokesBoxStyle = {
      backgroundColor: 'lightblue',
      ...flexBoxStyle,
    };

    const spokesBoxStyle = {
      backgroundColor: 'lightgreen',
      ...flexBoxStyle,
    };

    const Inactivespokes = {
      backgroundColor: 'red',
      ...flexBoxStyle,
    };

    return (
      <div className="welcome-container">
        {/* Link to download instructions page */}
        <Link
          to="/download"
          style={{
            float: 'right',
            margin: '1rem 2rem',
            padding: '0.5rem 1rem',
            textDecoration: 'none',
            border: '1px solid blue',
          }}
        >
          Download Instructions
        </Link>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ul>
            <li onClick={this.handleLogout}>
              <i className="fa fa-sign-out"></i> Log out
            </li>
          </ul>
          <Box>
            <Paper style={totalSpokesBoxStyle} elevation={3}>
              <Link to="/">
                <Typography variant="h6">
                  Total Branches: {this.state.data.length}
                </Typography>
              </Link>
            </Paper>
          </Box>

          <Box>
            <Paper style={spokesBoxStyle} elevation={3}>
              <Link to="/active-spokes">
                <Typography variant="h6">
                  Active Branches: {this.state.activeSpokes}
                </Typography>
              </Link>
            </Paper>
          </Box>

          <Box>
            <Paper style={Inactivespokes} elevation={3}>
              <Link to="/inactive-spokes">
                <Typography variant="h6">
                  Inactive Branches: {this.state.inactiveSpokes}
                </Typography>
              </Link>
            </Paper>
          </Box>
        </Box>

        <h1 align="center">Total Branches</h1>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Public IP</TableCell>
                <TableCell>Tunnel IP</TableCell>
                <TableCell>Branch Location</TableCell>
                <TableCell>Subnet</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data.map((item) => (
                <TableRow key={item.public_ip}>
                  <TableCell>{item.public_ip}</TableCell>
                  <TableCell>{item.tunnel_ip}</TableCell>
                  <TableCell>{item.branch_location}</TableCell>
                  <TableCell>{this.renderSubnets(item.subnet)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default Welcome;
