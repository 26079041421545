import React from 'react';
import './download.css';

const DownloadPage = () => {
  function copyCommand(id) {
    navigator.clipboard.writeText(document.getElementById(id).innerText);
  }

  return (
    <div className="container">
      <h1>Download Instructions</h1>
      <h2>ReachLink</h2>
      <ul>
        <li>
          Establish a communication between branches via a secure and
          light-weight tunnel over the Internet.
        </li>
        <li>Reach your Real subnet behind the private subnet from anywhere.</li>
        <li>Supports point-to-multi-point communication.</li>
        <li>More scalable.</li>
        <li>CPU as well as Resource utilization is less. </li>
        <li>Interactive dashboard.</li>
        <li>
          Get alert messages via registered mail, if any branch is Inactive.
        </li>
      </ul>
      <h3>Dashboard Features:</h3>
      <ul>
        <li>Get the connected & disconnected Branches.</li>
        <li>Provides information about routed subnets.</li>
        <li>Provision for ADD/DEL subnets.</li>
        <li>Provision for ACTIVATE/DEACTIVATE branches.</li>
        <li>Supports Diagnostics </li>
      </ul>
      <h2>System Requirements:</h2>
      <ul>
        <li>OS: Ubuntu 20.04</li>
        <li>RAM: 4GB</li>
        <li>Hard-Disk: 100GB</li>
        <li>NIC: 2</li>
        <ul>
          <li>1st Interface should be WAN</li>
          <li>2nd Interface should be LAN</li>
        </ul>
      </ul>
      <p>During Installation, it will ask for your Sign-In Info:</p>
      <ol start="1">
        <li>
          Enter the number of subnets you want to route. At least one subnet is
          required (can be added via the Dashboard after successful
          installation).
        </li>
        <li>Enter the subnet you want to route (e.g., 192.168.23.0/24).</li>
        <li>Enter your branch location.</li>
        <li>Enter your Registered mail ID with CloudEtel.</li>
        <li>
          Enter the password. Provide the valid Registered Mail Id & Password;
          otherwise, it won't proceed. If valid, you will get a message
          confirming successful registration.
        </li>
      </ol>
      <h2 style={{ marginBottom: 0 }}>Download Software:</h2>
      <a
        href="https://assets.cloudetel.com/cloudeteldev/reach_link/reachlink.tar"
        download
      >
        <button>Download ReachLink</button>
      </a>
      <br /> <br />
      <div className="command-box">
        <label htmlFor="command1">
          Or download the archive file with this command:
        </label>
        <pre id="command1">
          wget
          https://assets.cloudetel.com/cloudeteldev/reach_link/reachlink.tar
        </pre>
        <button className="copy-btn" onClick={() => copyCommand('command1')}>
          Copy
        </button>
      </div>
      <h2>Installation Instructions:</h2>
      <div className="command-box">
        <label htmlFor="command2">Untar the downloaded file:</label>
        <pre id="command2">tar -xvf reachlink.tar</pre>
        <button className="copy-btn" onClick={() => copyCommand('command2')}>
          Copy
        </button>
      </div>
      <div className="command-box">
        <label htmlFor="command3">Provide execution permission:</label>
        <pre id="command3">chmod +x reachlink</pre>
        <button className="copy-btn" onClick={() => copyCommand('command3')}>
          Copy
        </button>
      </div>
      <div className="command-box">
        <label htmlFor="command4">Execute the software:</label>
        <pre id="command4">./reachlink</pre>
        <button className="copy-btn" onClick={() => copyCommand('command4')}>
          Copy
        </button>
      </div>
      <p>With that you are ready to use Reach Link</p>
    </div>
  );
};

export default DownloadPage;
