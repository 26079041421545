import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const ActiveSpokesTable = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [activeSpokes, setActiveSpokes] = useState(0);
  const [Totalbanches, setTotalbanches] = useState(0);
  const [inactiveSpokes, setInactiveSpokes] = useState(0);
  const [activeSpokesData, setActiveSpokesData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const activeBranchesResponse = await axios.get('https://www.dev-linkbackend.cloudetel.com:3443/activebranches');
        if (activeBranchesResponse.status === 200) {
          setActiveSpokesData(activeBranchesResponse.data);
          setActiveSpokes(activeBranchesResponse.data.length);
          await fetchInactiveBranches();
          await fetchTotalBranches();
        } else {
          setError('Failed to fetch active branches');
        }
      } catch (error) {
        setError('Error fetching data');
      } finally {
        setIsLoading(false);
      }
    };

    const fetchInactiveBranches = async () => {
      try {
        const inactiveBranchesResponse = await axios.get('https://www.dev-linkbackend.cloudetel.com:3443/inactivebranches');
        setInactiveSpokes(inactiveBranchesResponse.data.length);
      } catch (error) {
        console.error(error);
      }
    };
    const fetchTotalBranches = async () => {
      try {
        const inactiveBranchesResponse = await axios.get('https://www.dev-linkbackend.cloudetel.com:3443/fetchTotalBranches');
        setTotalbanches(inactiveBranchesResponse.data.length);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures useEffect runs only once on component mount

  const diagnostics = async (spoke) => {
    const data = {
      public_ip: spoke.public_ip,
      tunnel_ip: spoke.tunnel_ip,
      branch_location: spoke.branch_location,
      subnet: spoke.subnet,
      vrf: spoke.vrf,
    };

    navigate(`/diagnostics`, { state: data });
  };
  const addSubnet = async (spoke) => {
    const data = {
      public_ip: spoke.public_ip,
      tunnel_ip: spoke.tunnel_ip,
      branch_location: spoke.branch_location,
      subnet: spoke.subnet,
      vrf: spoke.vrf,
    };

    navigate(`/addsubnet`, { state: data });
  };
  const addSubnet_branch = async (spoke) => {
    const data = {
      public_ip: spoke.public_ip,
      tunnel_ip: spoke.tunnel_ip,
      branch_location: spoke.branch_location,
      subnet: spoke.subnet,
      vrf: spoke.vrf,
    };

    navigate(`/addsubnet_branch`, { state: data });
  };
  const deleteSubnet = async (spoke) => {
    const data = {
      public_ip: spoke.public_ip,
      tunnel_ip: spoke.tunnel_ip,
      branch_location: spoke.branch_location,
      subnet: spoke.subnet,
      vrf: spoke.vrf,
    };

    navigate(`/deleteSubnet`, { state: data });
  };

  const deactivateSpoke = async (spoke) => {
    const data = {
      public_ip: spoke.public_ip,
      tunnel_ip: spoke.tunnel_ip,
      branch_location: spoke.branch_location,
      subnet: spoke.subnet,
      vrf: spoke.vrf,
    };

    try {
      const response = await axios.post('https://www.dev-linkbackend.cloudetel.com:3443/activate_spoke', data);
      console.log('Spoke Deactivated:', response.data);

      // Show alert
      alert('Spoke Deactivated successfully! Press "OK" & wait, We Redirecting in 20 seconds.');

      // Redirect after 5 seconds
      setTimeout(() => {
        // Replace 'your-redirect-url' with the actual URL you want to redirect to
        window.location.reload();
      }, 20000);
    } catch (error) {
      console.error('Activation failed:', error);
    }
  };

  const renderSubnets = (subnets) => {
    if (Array.isArray(subnets)) {
      return subnets.map((subnet, index) => (
        <span key={index}>{subnet}<br /></span>
      ));
    } else {
      return <span>{subnets}</span>;
    }
  };

  const flexBoxStyle = {
    flex: '1',
    margin: '10px',
    padding: '15px',
    borderRadius: '8px',
  };

  const totalSpokesBoxStyle = {
    backgroundColor: 'lightblue',
    ...flexBoxStyle,
  };

  const spokesBoxStyle = {
    backgroundColor: 'lightgreen',
    ...flexBoxStyle,
  };

  const inactiveSpokesStyle = {
    backgroundColor: 'red',
    ...flexBoxStyle,
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Paper style={totalSpokesBoxStyle} elevation={3}>
            <Link to="/">
              <Typography variant="h6">Total Branches: {Totalbanches}</Typography>
            </Link>
          </Paper>
        </Box>

        <Box>
          <Paper style={spokesBoxStyle} elevation={3}>
            <Typography variant="h6">Active Branches: {activeSpokes}</Typography>
          </Paper>
        </Box>

        <Box>
          <Paper style={inactiveSpokesStyle} elevation={3}>
            <Link to="/inactive-spokes">
              <Typography variant="h6">Inactive Branches: {inactiveSpokes}</Typography>
            </Link>
          </Paper>
        </Box>
      </Box>

      <h1>Active Branches</h1>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Public IP</TableCell>
              <TableCell>Tunnel IP</TableCell>
              <TableCell>Branch Location</TableCell>
              <TableCell>Subnet</TableCell>
              <TableCell>VRF</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeSpokesData.map((item) => (
              <TableRow key={item.public_ip}>
                <TableCell>{item.public_ip}</TableCell>
                <TableCell>{item.tunnel_ip}</TableCell>
                <TableCell>{item.branch_location}</TableCell>
                <TableCell>{renderSubnets(item.subnet)}</TableCell>
                <TableCell>{item.vrf}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => deactivateSpoke(item)}
                  >
                    Deactivate
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    onClick={() => diagnostics(item)}
                  >
                    Diagnostics
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    onClick={() => addSubnet(item)}
                  >
                    Add Subnet
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    onClick={() => addSubnet_branch(item)}
                  >
                    Add Subnet (branch)
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    onClick={() => deleteSubnet(item)}
                  >
                    Delete Subnet
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ActiveSpokesTable;
