// AddSubnet.js
import React, { useState } from 'react';
import axios from 'axios';
import {
  Paper,
  Box,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const AddSubnet = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    subnet: '',
    tunnel_ip: '',
    vrf: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const spokeData = location.state; // Retrieve the spoke data from the location state
      const data = {
        public_ip: spokeData.public_ip,
        tunnel_ip: spokeData.tunnel_ip,
        branch_location: spokeData.branch_location,
        subnet: formData.subnet,
        vrf: spokeData.vrf,
      };

      // Send a POST request to add subnet
      const response = await axios.post('https://www.dev-linkbackend.cloudetel.com:3443/add_subnet', data); // Update the URL
      console.log('Subnet added successfully:', response.data);

      // Show alert
      alert('Subnet added successfully! Press "OK" & wait, We Redirecting in 5 seconds.');

      // Redirect after 5 seconds
      setTimeout(() => {
        // Replace 'your-redirect-url' with the actual URL you want to redirect to
        navigate('/active-spokes');
      }, 5000);
    } catch (error) {
      console.error('Adding subnet failed:', error);
    }
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Paper elevation={3}>
            <Typography variant="h6">Add Subnet</Typography>
          </Paper>
        </Box>
      </Box>

      <form onSubmit={handleSubmit}>
        <TextField
          label="Subnet"
          name="subnet"
          value={formData.subnet}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Tunnel IP"
          name="tunnel_ip"
          value={location.state.tunnel_ip}
          disabled
          fullWidth
          margin="normal"
        />
        <TextField
          label="VRF"
          name="vrf"
          value={location.state.vrf}
          disabled
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Add Subnet
        </Button>
      </form>
    </div>
  );
};

export default AddSubnet;
