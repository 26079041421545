import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "./login.css";

const Login = ({ setLoginUser }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const login = async () => {
    try {
      const response = await axios.post("https://www.dev-linkbackend.cloudetel.com:3443/login", user);

      if (response.status === 200) {
        console.log('if condition');
        const { access_token, access_token_expiry, refresh_token } = response.data.data;

        Cookies.set("access_token", access_token);
        console.log("fist access");
        Cookies.set("access_token_expiry", access_token_expiry);
        Cookies.set("refresh_token", refresh_token);

        // Generate the zbx_session value separately (replace with your desired value)
        const zbxSessionValue = "eyJzZXNzaW9uaWQiOiJlNjkxMjU5YjM3ZmZmY2NlNDVmY2UzZjlhMTk1NTJhYiIsInNlcnZlckNoZWNrUmVzdWx0Ijp0cnVlLCJzZXJ2ZXJDaGVja1RpbWUiOjE2OTc0NDI0NTUsInNpZ24iOiIyYzBmMTFlZjgyYjdmMDBhODlmODk1NGEwNGZhZWEzMjRkNDZkYTJlZWRlYWUxYjM1NGYyZTY4OTMxNjZhY2MxIn0%3D";

        // Convert the expiration date to a JavaScript Date object
        const expirationDate = new Date("2023-10-25T07:25:13.243Z");
        
        Cookies.set("zbx_session", zbxSessionValue, { expires: expirationDate });

        const loggedInUser = { _id: access_token };
        Cookies.set("isvalid", "true");

        alert(`Logged In Successfully!`);
        setLoginUser(loggedInUser);
    navigate("/")
      } else {
        console.error("Login failed:", response.data.error);
        // Handle login error here
      }
    } catch (error) {
      console.error("Login failed:", error);
      // Handle login error here
    }
  };


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      login();
    }
  };

  return (
    <div className="login">
      <h1>Welcome to Reach_Link <br />Login</h1>
      <input
        type="text"
        name="email"
        value={user.email}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Enter your Email"
      />
      <input
        type="password"
        name="password"
        value={user.password}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Enter your Password"
      />
      <button className="button" onClick={login}>
        Login
      </button>
    </div>
  );
};

export default Login;
