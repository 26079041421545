import React, { Component } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Link } from 'react-router-dom';

class InactiveSpokesTable extends Component {
  state = {
    activeSpokesData: [],
    loading: true, // Add a loading state
  };

  activateSpoke = (spoke) => {
    const data = {
      public_ip: spoke.public_ip,
      tunnel_ip: spoke.tunnel_ip,
      branch_location: spoke.branch_location,
      subnet: spoke.subnet,
      vrf: spoke.vrf,
    };

    // Send a POST request to the server to activate the spoke
    axios.post('https://www.dev-linkbackend.cloudetel.com:3443/deactivate_spoke', data) // Change the URL if needed
      .then(response => {
        // Handle the response, e.g., show a success message
        console.log('Spoke activated:', response.data);

        // Show alert
        alert('Spoke activated successfully!  Press "OK" & wait, We Redirecting in 60 seconds.');

        // Redirect after 5 seconds
        setTimeout(() => {
          // Replace 'your-redirect-url' with the actual URL you want to redirect to
          window.location.reload();
        }, 60000);
      })
      .catch(error => {
        // Handle errors, e.g., show an error message
        console.error('Activation failed:', error);
      });
  };

  componentDidMount() {
    axios.get('https://www.dev-linkbackend.cloudetel.com:3443/fetchTotalBranches')
      .then(response => {
        this.setState({ data: response.data.length, loading: false });
      })
      .catch(error => {
        console.error(error);
      });

    axios.get('https://www.dev-linkbackend.cloudetel.com:3443/activebranches')
      .then(response => {
        if (response.status === 200) {
          this.setState({ activeSpokes: response.data.length }, () => {
            // Fetch inactive branches only if the status for active branches is 200
            this.fetchInactiveBranches();
          });
        } else {
          console.error('Failed to fetch active branches');
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  fetchInactiveBranches() {
    axios.get('https://www.dev-linkbackend.cloudetel.com:3443/inactivebranches')
      .then(response => {
        this.setState({ activeSpokesData: response.data });
        this.setState({ inactiveSpokes: response.data.length });
      })
      .catch(error => {
        console.error(error);
      });
  }

  renderSubnets = (subnets) => {
    if (Array.isArray(subnets)) {
      return subnets.map((subnet, index) => (
        <span key={index}>{subnet}<br /></span>
      ));
    } else {
      return <span>{subnets}</span>;
    }
  };

  render() {
    const flexBoxStyle = {
      flex: '1',
      margin: '10px',
      padding: '15px',
      borderRadius: '8px',
    };

    const totalSpokesBoxStyle = {
      backgroundColor: 'lightblue',
      ...flexBoxStyle,
    };

    const spokesBoxStyle = {
      backgroundColor: 'lightgreen',
      ...flexBoxStyle,
    };

    const inactiveSpokesStyle = {
      backgroundColor: 'red',
      ...flexBoxStyle,
    };

    if (this.state.loading) {
      // Display the progress bar while loading
      return <CircularProgress />;
    }

    return (
      <div>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Paper style={totalSpokesBoxStyle} elevation={3}>
              <Link to="/">
                <Typography variant="h6">Total Branches: {this.state.data}</Typography>
              </Link>
            </Paper>
          </Box>

          <Box>
            <Paper style={spokesBoxStyle} elevation={3}>
              <Link to="/active-spokes">
                <Typography variant="h6">Active Branches: {this.state.activeSpokes}</Typography>
              </Link>
            </Paper>
          </Box>
          <Box>
            <Paper style={inactiveSpokesStyle} elevation={3}>
              <Typography variant="h6">Inactive Branches: {this.state.inactiveSpokes}</Typography>
            </Paper>
          </Box>
        </Box>
        <h1>Inactive Branches </h1>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Public IP</TableCell>
                <TableCell>Tunnel IP</TableCell>
                <TableCell>Branch Location</TableCell>
                <TableCell>Subnet</TableCell>
                <TableCell>Activate</TableCell> {/* Add a new column for activation */}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.activeSpokesData.map(item => (
                <TableRow key={item.public_ip}>
                  <TableCell>{item.public_ip}</TableCell>
                  <TableCell>{item.tunnel_ip}</TableCell>
                  <TableCell>{item.branch_location}</TableCell>
                  <TableCell>{this.renderSubnets(item.subnet)}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => this.activateSpoke(item)}
                    >
                      Activate
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default InactiveSpokesTable;
