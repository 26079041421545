import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Welcome from './components/Welcome';
import ActiveSpokesTable from './components/ActiveSpokesTable';
import InactiveSpokesTable from './components/InactiveSpokesTable';
import Cookies from 'js-cookie';
import Login from './components/login/login';
import backgroundVideo from './assets/bgvideo/bg.mp4';
import NotFound from './components/login/NotFound';
import Diagnostics from './components/Diagnostics/diagnostics';
import AddSubnet from './components/AddSubnet';
import AddSubnet_branch from './components/AddSubnet_branch';
import DeleteSubnet from './components/DeleteSubnet';
import DownloadPage from './components/DownloadPage';

function App() {
  const [user, setLoginUser] = useState({});

  useEffect(() => {
    const userId = Cookies.get('access_token');
    if (userId) {
      setLoginUser({ _id: userId });
    }
  }, []);

  return (
    <Router>
      {/* Conditionally render the video based on the presence of the access token */}
      {!user._id && (
        <video autoPlay muted loop id="background-video">
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      <div className="main-container">
        <Routes>
          {/* Render Home component or Login based on user authentication */}
          <Route
            exact
            path="/"
            element={
              user && user._id ? (
                <Welcome setLoginUser={setLoginUser} />
              ) : (
                <Login setLoginUser={setLoginUser} />
              )
            }
          />
          {/* Render Home component or Login based on user authentication */}
          <Route
            exact
            path="/active-spokes"
            element={
              user && user._id ? (
                <ActiveSpokesTable setLoginUser={setLoginUser} />
              ) : (
                <Login setLoginUser={setLoginUser} />
              )
            }
          />
          {/* Render Home component or Login based on user authentication */}
          <Route
            exact
            path="/inactive-spokes"
            element={
              user && user._id ? (
                <InactiveSpokesTable setLoginUser={setLoginUser} />
              ) : (
                <Login setLoginUser={setLoginUser} />
              )
            }
          />
          <Route path="/diagnostics" element={<Diagnostics />} />
          <Route path="/download" element={<DownloadPage />} />
          <Route path="/addsubnet" element={<AddSubnet />} />
          <Route path="/addsubnet_branch" element={<AddSubnet_branch />} />
          <Route path="/deleteSubnet" element={<DeleteSubnet />} />
          <Route path="*" element={<NotFound />} />{' '}
          {/* Catch-all route for 404 errors */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
