import React, { useState } from 'react';
import {
  Tab,
  Tabs,
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Box,
  Typography,
  FormControl,
  Button,
  InputLabel,
  Input,
  LinearProgress,
} from '@mui/material';
import { useParams,useLocation } from 'react-router-dom';
import { testConnection } from '../../service/api.js';

function Diagnostics() {
  const location = useLocation();
  const { state: spokeData } = location;
  const  id  = spokeData.subnet


  const [testConn, settestConn] = useState({});
  const [currentTab, setCurrentTab] = useState('connectivity_test');
  const [IpAddress, setInternalIpAddress] = useState('');
  const [isValidIpAddress, setIsValidIpAddress] = useState(true);
  const [progress, setProgress] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const handleIpAddressChange = (event) => {
    const ipAddress = event.target.value;
    setInternalIpAddress(ipAddress);
    setIsValidIpAddress(validateIp(ipAddress));
    onValueChange(event); // Call the onValueChange function
  };
  const validateIp = (ipAddress) => {
    const parts = ipAddress.split('.');
    if (parts.length !== 4) {
      return false;
    }

    for (let i = 0; i < parts.length; i++) {
      const part = parseInt(parts[i]);
      if (isNaN(part) || part < 0 || part > 255) {
        return false;
      }
    }
    return true;
  };

  const ipToBinary = (ip) =>
    ip
      .split('.')
      .map((part) => parseInt(part, 10).toString(2).padStart(8, '0'))
      .join('');

  const isIPInSubnet = (targetIP, subnet) => {
    const [subnetAddress, subnetMask] = subnet.split('/');
    const targetBinary = ipToBinary(targetIP);
    const subnetBinary = ipToBinary(subnetAddress);
    const subnetMaskBinary =
      '1'.repeat(parseInt(subnetMask, 10)) + '0'.repeat(32 - parseInt(subnetMask, 10));

    return (
      (parseInt(targetBinary, 2) & parseInt(subnetMaskBinary, 2)) ===
      (parseInt(subnetBinary, 2) & parseInt(subnetMaskBinary, 2))
    );
  };

  const onValueChange = (e) => {
    const { name, value } = e.target;
    settestConn({ ...testConn, [name]: value });
  };

  const Connect_test = async () => {
    try {
      console.log("Started try func");
      setIsValidIpAddress(false);
      setProgress(0);

      console.log("Started try func");
      // Ensure that IpAddress is a valid IP address
      // if (!validateIp(IpAddress)) {
        
      // console.log("Error of ip address");
      //   displayAlert('Invalid IP address format', 'alert-error');
      //   setIsValidIpAddress(true);
      //   return;
      // }

      // Define your subnet here (adjust based on your requirements)
      const subnet = id;

      // Check if the provided IP address belongs to the specified subnet
      // if (!isIPInSubnet(IpAddress, subnet)) {
      //   displayAlert(
      //     `The IP address ${IpAddress} is not in the allowed subnet ${subnet}`,
      //     'alert-error'
      //   );
        
      // console.log("Ip not allowed in subnet");
      //   setIsValidIpAddress(true);
      //   return;
      // }

      // Perform the connectivity test only if the IP address is in the allowed subnet
      const response = await testConnection(testConn, id);
      const progressPercentage = response.data[0].progress;
      setProgress(progressPercentage);
      console.log(response.data[0].avg_rtt);

      if (response.data[0].avg_rtt === -1) {
        displayAlert('Not Connected', 'alert-error');
      } else {
        displayAlert(
          `Testing Connection with Round Trip Time ${response.data[0].avg_rtt}`,
          'alert-success'
        );
      }

      setTimeout(() => {
        window.location.reload(); // Refresh the page after 4 seconds
      }, 4000);

      setIsValidIpAddress(true);
    } catch (error) {
      displayAlert('Error posting data. Please try again.', 'alert-error');
      setIsValidIpAddress(true);
    }
  };

  const displayAlert = (message, className) => {
    const alertElement = document.getElementById('alertMessage');
    alertElement.textContent = message;
    alertElement.classList.add(className);
    alertElement.style.display = 'block';
  };

  return (
    <>
      <div className="HomePageContainer">
        <div id="page-wrapper" class="gray-bg">
          <h1>Diagnostics</h1>
          <div id="alertMessage" class="alert"></div>

          <LinearProgress variant="determinate" value={progress} />

          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Connectivity test" value="connectivity_test" />
          </Tabs>

          <Box hidden={currentTab !== 'connectivity_test'}>
            <Typography variant="h4">Connectivity test</Typography>
            <FormControl>
              <InputLabel>Destination</InputLabel>
              <Input
                name="subnet"
                value={IpAddress}
                onChange={handleIpAddressChange}
                error={!isValidIpAddress}
              />
            </FormControl>
            <FormControl>
              <Button variant="contained" onClick={Connect_test} disabled={!isValidIpAddress}>
                Test
              </Button>
            </FormControl>
          </Box>
        </div>
      </div>
    </>
  );
}

export default Diagnostics;
