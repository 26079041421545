// DeleteSubnet.js
import React, { useState } from 'react';
import axios from 'axios';
import {
  Paper,
  Box,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const DeleteSubnet = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [subnetToDelete, setSubnetToDelete] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    try {
      const spokeData = location.state; // Retrieve the spoke data from the location state

      if (!subnetToDelete) {
        alert('Please enter the subnet to delete.');
        return;
      }

      const data = {
        public_ip: spokeData.public_ip,
        tunnel_ip: spokeData.tunnel_ip,
        branch_location: spokeData.branch_location,
        subnet: subnetToDelete,
        vrf: spokeData.vrf,
      };

      setIsDeleting(true);

      // Send a POST request to delete subnet
      const response = await axios.post('https://www.dev-linkbackend.cloudetel.com:3443/del_subnet', data); // Update the URL
      console.log('Subnet deleted successfully:', response.data);

      // Show alert
      alert('Subnet deleted successfully! Press "OK" & wait, We Redirecting in 5 seconds.');

      // Redirect after 5 seconds
      setTimeout(() => {
        // Replace 'your-redirect-url' with the actual URL you want to redirect to
        navigate('/active-spokes');
      }, 5000);
    } catch (error) {
      console.error('Deleting subnet failed:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Paper elevation={3}>
            <Typography variant="h6">Delete Subnet</Typography>
          </Paper>
        </Box>
      </Box>

      <Typography variant="h6">Subnet Details:</Typography>
      <Box>
        <Typography>Public IP: {location.state.public_ip}</Typography>
        <Typography>Tunnel IP: {location.state.tunnel_ip}</Typography>
        <Typography>Branch Location: {location.state.branch_location}</Typography>
        <Typography>VRF: {location.state.vrf}</Typography>
      </Box>

      <TextField
        label="Enter Subnet to Delete"
        variant="outlined"
        value={subnetToDelete}
        onChange={(e) => setSubnetToDelete(e.target.value)}
        fullWidth
        margin="normal"
      />

      <Button
        onClick={handleDelete}
        variant="contained"
        color="secondary"
        disabled={isDeleting}
      >
        {isDeleting ? 'Deleting...' : 'Delete Subnet'}
      </Button>
    </div>
  );
};

export default DeleteSubnet;
